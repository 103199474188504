import "./App.scss";

import bio from "./assets/images/bio.jpg";
import pilates from "./assets/images/Pilates.jpg";
import sportsMassage from "./assets/images/sport_Massage.jpg";
import vitality from "./assets/images/vitality.jpg";
import pilatesGroup from "./assets/images/pilates_group.jpg";

function App() {
  return (
    <div className="App">
      <header>
        <h1>Twin-Kinetics</h1>
        <h3>Rehabilitation & Wellness</h3>
      </header>

      <div className="info">
        <h1>Services</h1>

        <div className="flex">
          <div className="block">
            <div className="image-container">
              <img src={sportsMassage} alt="Dance" />
            </div>
            <div className="text-container m-a">
              <h3>Sports Massage</h3>
              <p>
                Are you feeling stressed? fatigued? Suffering from constant
                headaches and muscle aches? Let it all go with a sports massage!
                A sports massage is the manual, systematic manipulation of the
                body in order to decrease injury potential and enhance training.
              </p>
            </div>
          </div>

          <div className="block">
            <div className="image-container">
              <img src={vitality} alt="Dance" />
            </div>
            <div className="text-container m-a">
              <h3>Vitality Fitness Testing</h3>
              <p>
                A quick and easy test that will tell you how fit you are. You'll
                receive an exercise plan based on your areas of need and your
                fitness goals. Entails: -Height, weight, waist circumference
                measurements -Strength & mobility tests -Health and fitness
                report You can earn up to 7000 vitality points! All payments
                made can be claimed back from medical aid.
              </p>
            </div>
          </div>

          <div className="block">
            <div className="image-container">
              <img src={bio} alt="Dance" />
            </div>
            <div className="text-container m-a">
              <h3>Biokinetics Evaluation</h3>
              <p>
                An individualised assessment entailing: -A thorough medical
                history -Measure blood pressure and heart rate -Postural
                analysis -Assessment of body composition and waist to hip ratio
                (Inbody 120 machine) -Measurement of muscular strength,
                endurance and power -Range of motion evaluation -Special tests
                relating to the joints and muscles if deemed necessary -Exercise
                plan going forward
              </p>
            </div>
          </div>

          <div className="block">
            <div className="image-container">
              <img src={bio} alt="Dance" />
            </div>
            <div className="text-container m-a">
              <h3>Biokinetics session</h3>
              <p>
                Based on the Biokinetics evaluation, detailed exercise
                programmes are tailored for each client and these can be
                followed at the practice or at home. Areas of focus:
                -Orthopaedic rehabilitation -Injury prevention -Lower back pain
                -Weight management -Pre/post natal exercise -Medical aid testing
                -Chronic disease management -Postural analysis -Sports
                performance For more information, contact us on
                twinkinetics@gmail.com.
              </p>
            </div>
          </div>

          <div className="block">
            <div className="image-container">
              <img src={pilates} alt="Dance" />
            </div>
            <div className="text-container m-a">
              <h3>Pilates or Fitness Evaluation</h3>
              <p>
                Prior to one joining one-on-one or group classes we perform an
                evaluation to assess any disease or health risks before
                exercising. Evaluation includes: -Medical history -Postural
                analysis -Basic strength and flexibility testing -Foundation of
                Pilates if deemed necessary.
              </p>
            </div>
          </div>

          <div className="block">
            <div className="image-container">
              <img src={pilatesGroup} alt="Dance" />
            </div>
            <div className="text-container m-a">
              <h3>Pilates and Fitness Group classes</h3>
              <p>
                1 class p/week =R300 p/month 2 classes p/week = R560 p/month
                Contact us on twinkinetics@gmail.com for schedules and more
                information.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="contact">
        <h1>For a booking please give us a Call to arrange a session!</h1>

        <div className="flex">
          <div className="contact-container">
            <h3>Mobile</h3>
            <a href="tel:+27844223704">+27 84 422 3704</a>
          </div>
          <div className="contact-container">
            <h3>Email</h3>
            <a href="mailto:twinkinetics@gmail.com">twinkinetics@gmail.com</a>
          </div>
        </div>

        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14361.266281830274!2d28.2063787!3d-25.8590563!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95673b956c31d1%3A0x8601c849f13ab568!2sTwin-%20Kinetics!5e0!3m2!1sen!2sza!4v1683108509403!5m2!1sen!2sza"
            allowfullscreen=""
            title="map"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default App;
